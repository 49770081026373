<template>
	<!--서비스 상세 페이지 - 보안-->
	<div class="Secontainer" v-if="computedValue === `ko`">
		<!--content1-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<h2 v-scrollanimation class="header_h2">
						안전하고 편리한 데이터 분석 프레임워크
					</h2>
					<h1 v-scrollanimation>모듈러스</h1>
					<p v-scrollanimation>
						모듈러스는 데이터 분석 프레임워크로<br />
						사용자가 작성한 코드에 반응하여 필요한 데이터를 실시간으로
						가시화하며,<br />
						자사의 암호화 솔루션 ECHC를 적용한 네트워크 환경을 제공하여<br />
						높은 수준의 보안 환경에서의 협업과 데이터 공유, 동형 분석 서비스
						이용이 가능합니다.<br />
						모듈러스를 사용하여 더욱 편리하고 보다 안전한 데이터 분석을 경험해
						보세요.
					</p>
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">지금 문의하기</button>
						</router-link>
						<router-link to="/pricingsecurity">
							<button class="btn--md btn--fill-gray">구매하기</button>
						</router-link>
						<a href="https://fcts-mod.com/" target="_blank">
							<button class="btn--md btn--fill-accent">접속하기</button>
						</a>
					</div>
				</div>
				<div class="servicecontent__mainimg">
					<!-- <img :src="securitymain" /> -->
				</div>
			</div>
		</div>
		<!--content1 end-->

		<!--content3-->
		<div id="content3">
			<div class="content3-title">
				<img :src="moduluslogo" />
				<p class="servicesecurity-content3">
					어디서든 안전하고 편리하게 사용할 수 있는 데이터 분석 전용 프레임워크
				</p>
			</div>
			<div class="conT__First">
				<div class="FirstBox">
					<img :src="pythonEditIllu" />
					<div class="FirstTextBox">
						<div class="HEad">
							<h4>파이썬 코드 편집 및 실행</h4>
						</div>
						<p>
							모듈러스는 데이터 분석에 특화된<br />
							브라우저 형 파이썬 개발도구로 어디서든<br />
							모듈러스에 접속하여 코드를 작성하고<br />
							실행할 수 있습니다.
						</p>
					</div>
				</div>
				<div class="FirstBox">
					<div class="FirstTextBox">
						<div class="HEad">
							<h4>로컬 파일 호출 및 저장</h4>
						</div>
						<p>
							로컬 환경에서 파일을 호출하고 사용합니다.<br />
							서버나 외부로 노출될 걱정 없이 안전하게 <br />사용하세요.
						</p>
					</div>
					<img :src="LocalFileIllu" />
				</div>
			</div>

			<div class="conT__Second">
				<div class="Second_Name">01</div>
				<div class="SecondBox">
					<img :src="DataFrameIllu" />
					<div class="SecondTextBox">
						<div class="Header">데이터 프레임 자동 가시화</div>
						<div class="Texts">
							코드에서 데이터 프레임을 자동으로 인식하고<br />
							가시화하여, 데이터를 직접 보면서 분석이<br />
							가능합니다.
						</div>
					</div>
				</div>
				<div class="Second_Name">02</div>
				<div class="SecondBox">
					<img :src="CodeDataIllu" />
					<div class="SecondTextBox">
						<div class="Header">실시간 코드 분석 및 데이터 표현</div>
						<div class="Texts">
							작성 중인 코드를 실시간으로 분석하여<br />
							현재 다루고 있는 데이터가 무엇인지 나타냅니다.<br />
							팀원 간 소통, 적합한 메서드 선택, 프로그래밍<br />
							학습 도구 등 다양한 용도로 사용해 보세요.
						</div>
					</div>
				</div>
				<div class="Second_Name">03</div>
				<div class="SecondBox">
					<img :src="ControllIllu" />
					<div class="SecondTextBox">
						<div class="Header">가시화 데이터 컨트롤 창 제공</div>
						<div class="Texts">
							가시화된 데이터를 다룰 수 있는 컨트롤 창을<br />
							별도로 제공합니다.<br />
							상세 데이터 확인, 이미지다운, csv다운 등<br />
							필요한 추가 기능을 사용할 수 있습니다.
						</div>
					</div>
				</div>
			</div>

			<div class="conT__Third">
				<div class="ThirtIntro">협업도 간편하고 편리하게!</div>
				<div class="ThirdBox">
					<div class="Third_One">
						<div class="TextOne">
							실시간 코드 공유를 통해 동시편집 및 확인 가능
						</div>
						<div class="TopsIMg">
							<img :src="StartUser" />
						</div>
					</div>
					<div class="Third_Two">
						<div class="Third_Tops">
							<div class="TopsText">소스 코드 저장 및 공유</div>
							<div class="TopsIMg">
								<img :src="CodeSave" />
							</div>
						</div>
						<div class="Third_Botm">
							<div class="TopsText">
								파일과 메시지를<br />전달 할 수 있는 메신저
							</div>
							<div class="TopsIMg">
								<img :src="FileUser" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="conT__Fourth">
				<div class="background_wrapper-img">
					<img :src="moduluspromotionbg" />
					<div class="servicecontent__textbox">
						<h1>타원곡선 기반 동형암호로<br />더욱 안전한 데이터 분석 환경</h1>
						<h2>안심하고 편리하게 사용하세요.</h2>
						<p>
							모듈러스는 모든 데이터를<br />
							자사의 ECHC 암호화 솔루션을 <br />
							적용한 안전한 환경에서 다루고 있습니다.<br />
							<br />
							기존 상용화된 암호화 처리와 <br />
							차별화된 자사만의 솔루션으로<br />
							데이터 유출 걱정 없이 안전하게 사용하세요.
						</p>
					</div>
				</div>
			</div>
		</div>
		<!--content3 end-->

		<div id="content6">
			<SwiperKr />
		</div>

		<div class="SeContainer_mobile">
			<div class="Sheme">
				<div class="TopText">
					모듈러스를 사용하여 더욱 편리하고<br />
					보다 안전한 데이터 분석을 경험해 보세요!
				</div>
				<div class="MidText">모듈러스</div>
				<div class="BomText">
					모듈러스는 데이터 분석 프레임워크로 사용자가 작성한<br />
					코드에 반응하여 필요한 데이터를 실시간으로 가시화하며,<br />
					자사의 암호화 솔류션 ECHC를 적용한 네트워크 환경을 제공하여<br />
					높은 수준의 보안 환경에서의 협업과<br />
					데이터 공유, 동형 분석 서비스 이용이 가능합니다.
				</div>
				<router-link to="/inquiry">
					<button class="AskBtn">지금 문의하기</button>
				</router-link>
			</div>
			<div class="mb__Cont1">
				<div class="mb-image"><img :src="Pc__Modulus" alt="" /></div>
				<div class="mb-text">
					<div class="Head">데이터 분석 프레임워크 특징</div>
					<div class="Conts">
						편리하고 쉽게 접근할 수 있게 어느 환경에서든<br />
						브라우저로 실행 가능한 파이썬 개발 도구입니다!
					</div>
				</div>
			</div>
			<div class="mb__Cont2">
				<div class="Cont2__Boxx">
					<div class="BackNum">01</div>
					<div class="NumToTex">파이썬 코드 편집 및 실행</div>
				</div>
				<div class="SubText">
					모듈러스는 데이터분석에 특화된<br />
					브라우형파이썬 개발도구로 어디서든<br />
					모듈러스에 접속하여 코드를 작성하고<br />
					실행 할 수 있습니다.
				</div>
				<div class="Cont2__Boxx">
					<div class="BackNum">02</div>
					<div class="NumToTex">로컬 파일 호출 및 저장</div>
				</div>
				<div class="SubText">
					브라우저형 개발 도구이지만 사용자<br />
					로컬의 파일을 불러오거나 저장할 수 있으며,<br />
					이러한 과정은 모두 로컬환경에서 실행되어<br />
					외부로 노출되는 일 없이 안전하게<br />
					사용할 수 있습니다.
				</div>
			</div>
			<div class="mb__Cont3">
				<div class="Boxx">
					<img :src="DataFrameIllu" />
					<div class="BoxxText">데이터 프레임 자동 가시화</div>
				</div>
				<div class="Boxx">
					<img :src="CodeDataIllu" />
					<div class="BoxxText">실시간 코드 분석 및 데이터 표현</div>
				</div>
				<div class="Boxx">
					<img :src="ControllIllu" />
					<div class="BoxxText">가시화 데이터 컨트롤 창 제공</div>
				</div>
			</div>
			<div class="mb__Cont3-1">
				<div class="background_wrapper-img">
					<img :src="moduluspromotionbgmb" />
				</div>
			</div>
			<div class="mb__Cont4">
				<div class="TopText_mb">요금이 궁금하신가요?</div>
				<div class="BtmText_mb">
					내 비지니스 유형에 적합한 플랜을 찾아보세요!
				</div>
				<router-link to="/pricingsecurity" class="mb-price-button">
					모듈러스 요금 확인
				</router-link>
				<router-link to="/pricingbimining" class="mb-price-button">
					BI Mining 요금 확인
				</router-link>
				<div class="TopText_mb">솔루션이 궁금하신가요?</div>
				<div class="BtmText_mb">
					내 비지니스 유형에 적합한 솔루션을 찾아보세요!
				</div>
				<router-link to="/solutioncrypto" class="mb-solution-button">
					암호스키마 솔루션 바로가기
				</router-link>
				<router-link to="/solutionvaccine" class="mb-solution-button">
					FC-Anti Virus 솔루션 바로가기
				</router-link>
				<router-link to="/" class="mb-solution-button">
					CryptaLedger Tech<br />
					솔루션 바로가기
				</router-link>
			</div>
		</div>
	</div>
	<div class="Secontainer" v-else-if="computedValue === `en`">
		<!--content1-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<h2 v-scrollanimation class="header_h2">
						Secure and Convenient Data Analysis Framework
					</h2>
					<h1 v-scrollanimation>Modulus</h1>
					<p v-scrollanimation>
						Modulus is a data analysis framework <br />
						that responds to user-written code by visualizing necessary data in
						real-time.<br />
						It provides a network environment enhanced with our crypto system
						solution, ECHC, <br />
						ensuring a high level of security for collaboration, data sharing,
						and homomorphic encryption services.<br />
						Experience more convenient and secure data analysis with Modulus.
					</p>
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">Contact Us</button>
						</router-link>
						<router-link to="/pricingsecurity">
							<button class="btn--md btn--fill-gray">Purchase</button>
						</router-link>
						<a href="https://fcts-mod.com/" target="_blank">
							<button class="btn--md btn--fill-accent">Access</button>
						</a>
					</div>
				</div>
				<div class="servicecontent__mainimg">
					<!-- <img :src="securitymain" /> -->
				</div>
			</div>
		</div>
		<!--content1 end-->

		<!--content3-->
		<div id="content3">
			<div class="content3-title">
				<img :src="moduluslogo" />
				<p class="servicesecurity-content3">
					A data analysis framework that can be used safely and conveniently
					from anywhere
				</p>
			</div>
			<div class="conT__First">
				<div class="FirstBox">
					<img :src="pythonEditIllu" />
					<div class="FirstTextBox">
						<div class="HEad">
							<h4>Python code editing and execution</h4>
						</div>
						<p>
							Modulus is a browser-based Python development tool<br />
							specialized for data analysis. You can access Modulus<br />
							from anywhere to edit and execute code.
						</p>
					</div>
				</div>
				<div class="FirstBox">
					<div class="FirstTextBox">
						<div class="HEad">
							<h4>Local file access and saving</h4>
						</div>
						<p>
							Access and use files in a local environment. <br />
							Use them safely without worrying about <br />exposure to servers
							or external sources.
						</p>
					</div>
					<img :src="LocalFileIllu" />
				</div>
			</div>

			<div class="conT__Second">
				<div class="Second_Name">01</div>
				<div class="SecondBox">
					<img :src="DataFrameIllu" />
					<div class="SecondTextBox">
						<div class="Header">Automatic visualization of data frames</div>
						<div class="Texts">
							The code automatically detects and visualizes data frames, <br />
							allowing you to analyze the data by viewing it directly.
						</div>
					</div>
				</div>
				<div class="Second_Name">02</div>
				<div class="SecondBox">
					<img :src="CodeDataIllu" />
					<div class="SecondTextBox">
						<div class="Header">
							Real-time code analysis and data visualization
						</div>
						<div class="Texts">
							"Real-time code analysis reveals the data<br />
							you are currently working with<br />
							Use it for various purposes, including team communication, <br />
							selecting appropriate methods, and learning programming tools."
						</div>
					</div>
				</div>
				<div class="Second_Name">03</div>
				<div class="SecondBox">
					<img :src="ControllIllu" />
					<div class="SecondTextBox">
						<div class="Header">
							Provision of Data Visualization Control Panel
						</div>
						<div class="Texts">
							A separate control panel is provided to manage visualized data.<br />
							You can use additional features such as detailed data
							inspection,<br />
							image saving, and CSV download.
						</div>
					</div>
				</div>
			</div>

			<div class="conT__Third">
				<div class="ThirtIntro">Collaboration made easy and convenient!</div>
				<div class="ThirdBox">
					<div class="Third_One">
						<div class="TextOne">
							Simultaneous editing and verification possible<br />
							through real-time code sharing
						</div>
						<div class="TopsIMg">
							<img :src="StartUser" />
						</div>
					</div>
					<div class="Third_Two">
						<div class="Third_Tops">
							<div class="TopsText">Source code storage and sharing</div>
							<div class="TopsIMg">
								<img :src="CodeSave" />
							</div>
						</div>
						<div class="Third_Botm">
							<div class="TopsText">
								A messenger for sharing files<br />and messages
							</div>
							<div class="TopsIMg">
								<img :src="FileUser" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="conT__Fourth">
				<div class="background_wrapper-img">
					<img :src="moduluspromotionbg" />
					<div class="servicecontent__textbox">
						<h1>
							Elliptic curve-based homomorphic encryption <br />for a more
							secure data analysis environment
						</h1>
						<h2>Use it with confidence and convenience.</h2>
						<p>
							Modulus handles all data in a secure environment<br />
							using its own ECHC crypto system solution. <br />
							<br />
							Safely use your data without worrying about leakage <br />
							with our unique solution, which is differentiated from <br />
							existing commercial encryption methods.
						</p>
					</div>
				</div>
			</div>
		</div>
		<!--content3 end-->

		<div id="content6">
			<SwiperKr />
		</div>

		<div class="SeContainer_mobile">
			<div class="Sheme">
				<div class="TopText">
					Experience more convenient <br />and secure data analysis with
					Modulus.
				</div>
				<div class="MidText">Modulus</div>
				<div class="BomText">
					Modulus is a data analysis framework<br />
					that responds to user-written code by visualizing necessary data in
					real-time.<br />
					It provides a network environment enhanced with our crypto system
					solution, ECHC,<br />
					ensuring a high level of security for collaboration,<br />data
					sharing, and homomorphic encryption services.
				</div>
				<router-link to="/inquiry">
					<button class="AskBtn">Contact Us</button>
				</router-link>
			</div>
			<div class="mb__Cont1">
				<div class="mb-image"><img :src="Pc__Modulus" alt="" /></div>
				<div class="mb-text">
					<div class="Head">Features of the Data Analysis Framework</div>
					<div class="Conts">
						A Python development tool that is easy to access and
						<br />
						can run in a browser, making it usable in any environment!
					</div>
				</div>
			</div>
			<div class="mb__Cont2">
				<div class="Cont2__Boxx">
					<div class="BackNum">01</div>
					<div class="NumToTex">Python code editing and execution</div>
				</div>
				<div class="SubText">
					Modulus is a browser-based Python development tool<br />
					specialized for data analysis. You can access Modulus<br />
					from anywhere to edit and execute code.
				</div>
				<div class="Cont2__Boxx">
					<div class="BackNum">02</div>
					<div class="NumToTex">Local file access and saving</div>
				</div>
				<div class="SubText">
					Access and use files in a local environment. <br />
					Use them safely without worrying about <br />exposure to servers or
					external sources.
				</div>
			</div>
			<div class="mb__Cont3">
				<div class="Boxx">
					<img :src="DataFrameIllu" />
					<div class="BoxxText">Automatic visualization of data frames</div>
				</div>
				<div class="Boxx">
					<img :src="CodeDataIllu" />
					<div class="BoxxText">
						Real-time code analysis and data visualization
					</div>
				</div>
				<div class="Boxx">
					<img :src="ControllIllu" />
					<div class="BoxxText">
						Provision of Data Visualization Control Panel
					</div>
				</div>
			</div>
			<div class="mb__Cont3-1">
				<div class="background_wrapper-img">
					<img :src="moduluspromotionbgmben" />
				</div>
			</div>
			<div class="mb__Cont4">
				<div class="TopText_mb">Curious about the pricing?</div>
				<div class="BtmText_mb">
					Find the plan that's right for your business type!
				</div>
				<router-link to="/pricingsecurity" class="mb-price-button">
					Modulus Check Pricing
				</router-link>
				<router-link to="/pricingbimining" class="mb-price-button">
					BI Mining Check Pricing
				</router-link>
				<div class="TopText_mb">Curious about the solution?</div>
				<div class="BtmText_mb">
					Find the plan that's right for your business type!
				</div>
				<router-link to="/solutioncrypto" class="mb-solution-button">
					Crypto Schema Go to Solution
				</router-link>
				<router-link to="/solutionvaccine" class="mb-solution-button">
					FC-Anti Virus Go to Solution
				</router-link>
				<router-link to="/" class="mb-solution-button">
					CryptaLedger Tech <br />Go to Solution
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import mfcImg from '../assets/images/service/mfc.svg';
import echcImg from '../assets/images/service/echc.svg';
import phcImg from '../assets/images/service/phc.svg';
import securityBg from '../assets/images/service/securitybg.jpg';
import speciality4 from '../assets/images/service/speciality4.svg';
import speciality5 from '../assets/images/service/speciality5.svg';
import speciality6 from '../assets/images/service/speciality6.svg';
import ExImg from '../assets/images/service/ExImg.svg';
import PythonEdit from '../assets/images/service/PythonEdit.svg';
import LocalFile from '../assets/images/service/LocalFile.svg';
import Pc__Modulus from '../assets/images/service/Pc__Modulus.svg';
import pythonEditIllu from '../assets/images/service/pythonEditIllu.svg';
import DataFrameIllu from '../assets/images/service/DataFrameIllu.svg';
import LocalFileIllu from '../assets/images/service/LocalFileIllu.svg';
import CodeDataIllu from '../assets/images/service/CodeDataIllu.svg';
import ControllIllu from '../assets/images/service/ControllIllu.svg';
import CodeSave from '../assets/images/service/CodeSave.svg';
import FileUser from '../assets/images/service/FileUser.svg';
import StartUser from '../assets/images/service/StartUser.svg';
import service1 from '../assets/images/service/security1.jpg';
import service2 from '../assets/images/service/security2.jpg';
import service3 from '../assets/images/service/security3.jpg';
import service4 from '../assets/images/service/security4.jpg';
import service5 from '../assets/images/service/security5.jpg';
import securitymain from '../assets/images/service/securitymain.png';
import content3img from '../assets/images/service/serviceseurity-content3.svg';
import security1 from '../assets/images/service/security1.svg';
import security2 from '../assets/images/service/security2.svg';
import security3 from '../assets/images/service/security3.svg';
import ModulusIcon from '../assets/images/service/ModulusIcon.png';
import ServiceI1 from '../assets/images/service/ServiceI1.png';
import BrowserIcon from '../assets/images/service/BrowserIcon.png';
import MatpolibIcon from '../assets/images/service/MatpolibIcon.png';
import DataAnalyzed from '../assets/images/service/DataAnalyzed.png';
import ProgrammingIcon from '../assets/images/service/ProgrammingIcon.png';
import moduluslogo from '../assets/images/modulus/moduluslogo.svg';
import moduluspromotionbg from '../assets/images/modulus/moduluspromotionbg.svg';
import moduluspromotionbgmb from '../assets/images/modulus/moduluspromotionbgmb.svg';
import moduluspromotionbgmben from '../assets/images/modulus/moduluspromotionbgmben.svg';

export default {
	data() {
		return {
			service: 1,
			mfcImg,
			CodeSave,
			FileUser,
			StartUser,
			ControllIllu,
			DataFrameIllu,
			LocalFileIllu,
			CodeDataIllu,
			Pc__Modulus,
			LocalFile,
			PythonEdit,
			pythonEditIllu,
			ExImg,
			echcImg,
			phcImg,
			securityBg,
			speciality4,
			speciality5,
			speciality6,
			service1,
			service2,
			service3,
			service4,
			service5,
			securitymain,
			content3img,
			security1,
			security2,
			security3,
			ModulusIcon,
			ServiceI1,
			BrowserIcon,
			MatpolibIcon,
			DataAnalyzed,
			ProgrammingIcon,
			moduluslogo,
			moduluspromotionbg,
			moduluspromotionbgmb,
			moduluspromotionbgmben,
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
	methods: {
		showService1() {
			this.service = 1;
		},
		showService2() {
			this.service = 2;
		},
		showService3() {
			this.service = 3;
		},
		showService4() {
			this.service = 4;
		},
		showService5() {
			this.service = 5;
		},
	},
};
</script>

<style></style>
